<template>
  <div>
    <div v-if="!$route.query.code">
      <a v-if="o365_auth_link"
        class="btn btn-outline-primary"
        :href="o365_auth_link"
        >
        <img
          height="36"
          src="@/assets/images/logos/Office 365.webp"
        >
        Login with O365
      </a>
      <span v-else>loading...</span>
    </div>
    <span v-else>
      Onboarding finished
    </span>
    <div class="mt-1">
      <a target="_blank" href="https://saasment.com/policy">Privacy policy & terms</a>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      sso: null,
      o365_auth_link: '',
    }
  },
  mounted() {
    this.sso = this.$route.query.code
    if (this.sso) {
      useJwt.addLogin({
        type: 'sso',
        user: 'root',
        app_name: 'Office 365',
        details: {
          code: this.$route.query,
          flow: JSON.parse(localStorage.getItem('o365Flow')),
        },
      }).then(() => {
        this.$router.push('Integrations')
      })
    } else {
      useJwt.getO365Link({}).then(response => {
        localStorage.setItem('o365Flow', JSON.stringify(response.data))
        this.o365_auth_link = response.data
      })
    }
  },
}
</script>
