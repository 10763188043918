<template>
  <div>
    <a v-if="!this.$route.query.code"
      class="btn btn-outline-primary"
      href="https://www.dropbox.com/oauth2/authorize?client_id=p9zmofzeryc1pay&redirect_uri=https://beta.saasment.com/dropbox&response_type=code&token_access_type=offline"
      >
      <img
        height="36"
        src="@/assets/images/logos/DropBox.webp"
      >
      Login with DropBox
    </a>
    <span v-else>
      Onboarding finished
    </span>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      sso: null,
    }
  },
  mounted() {
    this.sso = this.$route.query.code
    if (this.sso) {
      useJwt.addLogin({
        type: 'sso',
        user: 'root',
        app_name: 'DropBox',
        details: { code: this.sso },
      }).then(() => {
        this.$router.push('Integrations')
      })
    }
  },
}
</script>
