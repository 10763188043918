<template>

  <div>
    <b-row>
      <b-col cols="12" class="text-center">
        <b-card
          bg-variant="primary"
          text-variant="white"
        >
          <b style="font-size:24px">Start onboarding cloud applications to protect your organization</b>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        v-for="login in availableLogins"
        :key="login.name"
        xl="3"
      >
        <b-card
          no-body
        >
          <b-card-body class="px-0">
            <div class="text-center border-bottom">
              <img
                height="72"
                :src="require(`@/assets/images/logos/${login.name}.webp`)"
                class="mt-1 pb-2"
              >
            </div>
            <div class="px-2">
              <h3 class="mt-2">{{login.name}}</h3>
              <p>{{ login.description }}</p>
              <div class="card-footer border-0 m-0 p-0">
                <b-row>
                  <b-col cols="6">
                    <div v-if="login.identity">
                      {{ login.identity }} <span class="text-success" v-if="login.valid">{{ login.status }}</span><span class="text-danger" v-else>Failed</span>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="text-right">
                      <b-button v-b-modal="`modal-${login.name}`" class="p-0" variant="flat-primary">Setup</b-button>
                    </div>
                  </b-col>
                </b-row>

                <b-modal
                  :id="`modal-${login.name}`"
                  content-class="shadow"
                  :title="`Integrate with ${login.name}`"
                  no-fade
                  size="lg"
                  hide-footer
                  ok-title="Accept"
                >
                  <integration-wizard @finishWizard="getIntegrations" :name="login.name" :config="config[login.name]"></integration-wizard>
                </b-modal>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BButton, BModal,
} from 'bootstrap-vue'
import store from '@/store'
import useApplicationsList from './api/useApplicationsList'
import applicationStoreModule from './api/applicationStoreModule'
import IntegrationWizard from './IntegrationWizard.vue'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BCardBody,
    IntegrationWizard,
  },
  data() {
    return {
      logins: [],
      config: {
        'Google Workspace': {
          is_root: true,
          is_sso: true,
          default_auth: 'sso',
          default_user: 'root',
        },
        DropBox: {
          is_root: true,
          is_sso: true,
          default_auth: 'sso',
          default_user: 'root',
        },
        'Office 365': {
          is_root: true,
          is_sso: true,
          default_auth: 'sso',
          default_user: 'root',
        },
        AWS: {
          is_limited: true,
          is_role: true,
          default_auth: 'role',
          default_user: 'limited',
        },
      },
    }
  },
  computed: {
    availableLogins() {
      return this.logins.filter(login => Object.keys(this.config).includes(login.name))
      // return this.logins
    },
  },
  mounted() {
    this.getIntegrations()
  },
  methods: {
    getIntegrations() {
      const logins = JSON.parse(localStorage.getItem('logins'))
      if (logins) {
        this.logins = logins
      }
      this.fetchLogins(null, response => {
        this.logins = response
      })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-application'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, applicationStoreModule)

    const {
      fetchLogins,
    } = useApplicationsList()

    return {
      fetchLogins,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
