<template>
  <div>
    <b-row class="pb-4">
      <b-col
        cols="12"
        class="mb-2"
      >
        <h3 class="mb-0">
          Authentication Details
        </h3>
        <small class="text-muted">Enter Your Integration Details.</small>
      </b-col>
      <b-col md="6" v-if="authSelected === 'login'">
        <b-form-group
          label="Username"
          label-for="v-username"
        >
          <b-form-input
            id="v-username"
            v-model="username"
            required
            placeholder="Enter Username"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" v-if="authSelected ==='login'">
        <b-form-group
          label="Password"
          label-for="v-password"
        >
          <b-form-input
            id="v-password"
            type="password"
            v-model="password"
            required
            placeholder="Enter Password"
          />
        </b-form-group>
      </b-col>
      <b-col md="12" v-if="authSelected ==='api'">
        <b-form-group
          label="API Token"
          label-for="v-token"
        >
          <b-form-input
            id="v-token"
            required
            placeholder="Enter API Token"
            v-model="token"
          />
        </b-form-group>
      </b-col>
      <b-col md="12" v-if="authSelected ==='role'">
        <div class="text-center">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/MzS8SRXd1eA?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <h4 class="mt-1">Follow the steps to integrate Saasment with your AWS</h4>
        <ol>
          <li>Open your AWS console</li>
          <li>Goto "Identity and Access Management (IAM)"</li>
          <li>Under "Access management" click on "Roles"</li>
          <li>Create a new Role</li>
          <li>Choose "Another AWS account"</li>
          <li>At the "Account ID" enter "867468277581"</li>
          <li>Select "ReadOnlyAccess"</li>
          <li>Set a "Role name"</li>
          <li>Submit the new role "ARN" in below input label:</li>
        </ol>
        <b-form-group
          label="Role"
          label-for="v-role"
        >
          <b-form-input
            id="v-role"
            required
            placeholder="Enter Role ARN"
            v-model="role"
          />
        </b-form-group>
          <b-button variant="primary" class="mt-1 float-right" @click="formSubmitted">Submit</b-button><b-button variant="flat-primary float-right" class="mt-1 ml-1" @click="authSelected='keys'">Use Accesskey</b-button>
      </b-col>
      <b-col md="12" v-if="authSelected ==='keys'">
        <b-form-group
          label="keyId"
          label-for="v-keyId"
        >
          <b-form-input
            id="v-keyId"
            required
            placeholder="Enter Accesskey ID"
            v-model="accessKeyId"
          />
        </b-form-group>
        <b-form-group
          label="key"
          label-for="v-key"
        >
          <b-form-input
            id="v-key"
            required
            placeholder="Enter Accesskey"
            v-model="accessKey"
          />
        </b-form-group>
        <b-button variant="primary" class="mt-1 float-right" @click="formSubmitted">Submit</b-button><b-button variant="flat-primary float-right" class="mt-1 ml-1" @click="authSelected='role'">Use Role</b-button>
      </b-col>
      <b-col md="12" class="text-center mt-2" v-if="authSelected ==='sso'">
          <div v-if="name == 'Google Workspace'">
            <google-workspace @getSSO="getSSO" />
          </div>
          <div v-if="name == 'DropBox'">
            <drop-box @getSSO="getSSO" />
          </div>
          <div v-if="name == 'Office 365'">
            <o-365 @getSSO="getSSO" />
          </div>
      </b-col>
    </b-row>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="horizontal"
      back-button-text="Previous"
      class="wizard-horizontal mb-3 shadow-none"
      @on-complete="formSubmitted"
      v-if="0"
    >
      <template slot="footer" slot-scope="props">
       <div class="wizard-footer-left">
           <wizard-button @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">Next</wizard-button>
          <!-- <wizard-button  class="wizard-footer-right finish-button" :style="props.fillButtonStyle">  {{props.isLastStep ? 'Done' : 'Next'}}</wizard-button> -->
          <b-button v-else-if="authSelected != 'sso'" variant="primary">Done</b-button>
        </div>
      </template>
      <tab-content title="User">
        <b-row>
          <b-col
            cols="12"
          >
            <h3 class="mb-0">
              User Type
            </h3>
            <small class="text-muted">
              Which type of user do you want to share access?
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-radio
              v-model="userSelected"
              name="some-radio8"
              value="limited"
              :disabled="!config.is_limited"
              class="custom-control-primary mt-1"
            >
              Limited User <p class="text-muted">Automate scenarios scoped only by the given user</p>
            </b-form-radio>
            <b-form-radio
              v-model="userSelected"
              name="some-radio8"
              value="root"
              :disabled="!config.is_root"
              class="custom-control-primary"
            >
              Root Account <p class="text-muted">Enable us to create users to execute scenraios</p>
            </b-form-radio>
          </b-col>
        </b-row>
      </tab-content>

      <!-- account datails tab -->
      <tab-content title="Type">
        <b-row>
          <b-col
            cols="12"
          >
            <h3 class="mb-0">
              Authentication Type
            </h3>
            <small class="text-muted">
              How do you want to access your account?
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-radio
              v-model="authSelected"
              name="some-radio9"
              value="api"
              :disabled="!config.is_api"
              class="custom-control-primary mt-1"
            >
              API Token <p class="text-muted">Use generated API Token to access your account</p>
            </b-form-radio>
            <b-form-radio
              v-model="authSelected"
              name="some-radio9"
              :disabled="!config.is_sso"
              value="sso"
              class="custom-control-primary"
            >
              Single Sign On <p class="text-muted">Use provided SSO to access your account</p>
            </b-form-radio>
            <b-form-radio
              v-model="authSelected"
              name="some-radio9"
              value="login"
              :disabled="!config.is_login"
              class="custom-control-primary"
            >
              Username & Password <p class="text-muted">Share your authentication detauls</p>
            </b-form-radio>
            <b-form-radio
              v-model="authSelected"
              name="some-radio9"
              value="role"
              :disabled="!config.is_role"
              class="custom-control-primary"
            >
              Assume Role <p class="text-muted">Grant access to Saasment audit role </p>
            </b-form-radio>
          </b-col>
        </b-row>
      </tab-content>

      <!-- personal info tab -->
      <tab-content title="Details">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h3 class="mb-0">
              Authentication Details
            </h3>
            <small class="text-muted">Enter Your Integration Details.</small>
          </b-col>
          <b-col md="6" v-if="authSelected === 'login'">
            <b-form-group
              label="Username"
              label-for="v-username"
            >
              <b-form-input
                id="v-username"
                v-model="username"
                required
                placeholder="Enter Username"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="authSelected ==='login'">
            <b-form-group
              label="Password"
              label-for="v-password"
            >
              <b-form-input
                id="v-password"
                type="password"
                v-model="password"
                required
                placeholder="Enter Password"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="authSelected ==='api'">
            <b-form-group
              label="API Token"
              label-for="v-token"
            >
              <b-form-input
                id="v-token"
                required
                placeholder="Enter API Token"
                v-model="token"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="authSelected ==='role'">
            <div class="text-center">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/MzS8SRXd1eA?start=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <h4 class="mt-1">Follow the steps to integrate Saasment with your AWS</h4>
            <ol>
              <li>Open your AWS console</li>
              <li>Goto "Identity and Access Management (IAM)"</li>
              <li>Under "Access management" click on "Roles"</li>
              <li>Create a new Role</li>
              <li>Choose "Another AWS account"</li>
              <li>At the "Account ID" enter "867468277581"</li>
              <li>Select "ReadOnlyAccess"</li>
              <li>Set a "Role name"</li>
              <li>Submit the new role "ARN" in below input label:</li>
            </ol>
            <b-form-group
              label="Role"
              label-for="v-role"
            >
              <b-form-input
                id="v-role"
                required
                placeholder="Enter Role ARN"
                v-model="role"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="text-center mt-2" v-if="authSelected ==='sso'">
             <div v-if="name == 'Google Workspace'">
               <google-workspace @getSSO="getSSO" />
             </div>
             <div v-if="name == 'DropBox'">
               <drop-box @getSSO="getSSO" />
             </div>
             <div v-if="name == 'Office 365'">
               <o-365 @getSSO="getSSO" />
             </div>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormRadio,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GoogleWorkspace from './sso/GoogleWorkspace.vue'
import DropBox from './sso/DropBox.vue'
import O365 from './sso/o365.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    GoogleWorkspace,
    DropBox,
    O365,
  },
  data() {
    return {
      authSelected: '',
      token: '',
      role: '',
      username: '',
      password: '',
      userSelected: '',
      accessKey: '',
      accessKeyId: '',
      sso: null,
    }
  },
  mounted() {
    this.authSelected = this.config.default_auth
    this.userSelected = this.config.default_user
  },
  methods: {
    getSSO(sso) {
      this.sso = sso
    },
    formSent() {
      this.$root.$emit('bv::hide::modal', `modal-${this.name}`)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      this.$emit('finishWizard')
    },
    formSubmitted() {
      if (this.authSelected === 'api') {
        useJwt.addLogin({
          type: this.authSelected,
          user: this.userSelected,
          app_name: this.name,
          details: this.token,
        })
      } else if (this.authSelected === 'username') {
        useJwt.addLogin({
          type: this.authSelected,
          user: this.userSelected,
          app_name: this.name,
          details: { username: this.username, password: this.password },
        })
      } else if (this.authSelected === 'role') {
        useJwt.addLogin({
          type: this.authSelected,
          user: this.userSelected,
          app_name: this.name,
          details: { role: this.role },
        }).then(() => {
          this.formSent()
        })
      } else if (this.authSelected === 'keys') {
        useJwt.addLogin({
          type: this.authSelected,
          user: this.userSelected,
          app_name: this.name,
          details: {
            accessKey: this.accessKey,
            accessKeyId: this.accessKeyId,
          },
        }).then(() => {
          this.formSent()
        })
      } else {
        useJwt.addLogin({
          type: this.authSelected,
          user: this.userSelected,
          app_name: this.name,
          details: this.sso,
        })
      }
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
}
</script>
